<template>
  <v-container fluid >
    <v-row>
      <h1 class="font-weight-light">{{ $t('profile.title') }}</h1>
    </v-row>
    <v-row>
      <v-col>
      <v-card>
        <v-card-title>
          {{ $t('profile.edit') }}
        </v-card-title>
        <v-card-text>
          <v-alert
              :value="show_error_profile"
              type="error"
          >
            {{ error_profile }}
          </v-alert>
          <v-form>
            <v-text-field
                prepend-icon="person"
                name="username"
                v-model="$store.state.user.username"
                :label="$t('profile.login')"
                type="text"
                disabled
            />
            <v-text-field
                prepend-icon="email"
                name="email"
                v-model="$store.state.user.email"
                :label="$t('profile.email')"
                type="email"
            />
            <v-layout>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    name="first_name"
                    v-model="$store.state.user.first_name"
                    :label="$t('profile.firstname')"
                    type="text"
                />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    name="last_name"
                    v-model="$store.state.user.last_name"
                    :label="$t('profile.lastname')"
                    type="text"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="accent"
                 x-large
                 text
                 @click.prevent="saveProfile"
          >{{ $t('profile.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-col>
      <v-col>

      <v-card>
        <v-card-title>{{ $t('profile.change_password') }}</v-card-title>
        <v-card-text>
          <v-alert
              :value="show_error_password"
              type="error"
          >
            {{ error_password }}
          </v-alert>
          <v-form>
            <v-text-field
                prepend-icon="password"
                name="password"
                v-model="password_old"
                :label="$t('profile.current_password')"
                type="password"
            />
            <v-text-field
                prepend-icon="password"
                name="password_new1"
                v-model="password_new1"
                :label="$t('profile.new_password')"
                type="password"
            />
            <v-text-field
                prepend-icon="password"
                name="password_new2"
                v-model="password_new2"
                :label="$t('profile.new_password').concat(' (', $t('profile.confirm'), ')')"
                type="password"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="accent"
                 x-large
                 text
                 @click.prevent="saveNewPassword"
          >{{ $t('profile.save_password') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      </v-col>
    </v-row>

    <v-snackbar
        v-model="snackbar"
        :timeout="2000"
    >
      {{ snackbar_message }}
    </v-snackbar>
  </v-container>
</template>


<script>
import axios from 'axios';

export default {
  name: "Profile",
  data: function () {
    return {
      show_error_profile: false,
      error_profile: '',

      show_error_password: false,
      error_password: '',
      password_old: '',
      password_new1: '',
      password_new2: '',
      snackbar: false,
      snackbar_message: '',
    }
  },
  methods: {
    saveProfile() {
      this.$store.dispatch('updateProfile', {
        email: this.$store.state.user.email.trim(),
        first_name: this.$store.state.user.first_name,
        last_name: this.$store.state.user.last_name,
      }).then(() => {
        this.snackbar = true
        this.snackbar_message = "Profile updated!"
      })
          .catch(err => {
            this.error_profile = "Uh oh, something went wrong.. " + err.message,
            this.show_error_profile = true
          })
    },
    saveNewPassword() {
      if (this.password_new1 === this.password_new2) {
        const url = process.env.VUE_APP_API_URL + '/insights/password-change/'
        axios.post(url, {
          old_password: this.password_old,
          new_password1: this.password_new1,
          new_password2: this.password_new2,
        })
            .then(() => {
              this.snackbar = true
              this.snackbar_message = "Password changed!"
            })
            .catch(err => {
              this.error_password = "Uh oh, something went wrong.. " + err.message,
              this.show_error_password = true
            })
      } else {
        this.snackbar = true
        this.snackbar_message = "New passwords don't match!"
      }
    }
  }
}
</script>

